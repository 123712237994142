import React, { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import Sigin from "../components/signin"
import Alert from "@material-ui/lab/Alert"
import { SnackbarProvider } from "notistack"
import MuiAlert from "@material-ui/lab/Alert"
import { useTranslation } from "react-i18next"
import SiteContext from "../context/siteContext"
import InputPhone from "../components/helpers/input-phone"

import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  Divider,
  Backdrop,
  TableRow,
  Snackbar,
  TableBody,
  TableCell,
  Container,
  TextField,
  Typography,
  IconButton,
  makeStyles,
  FormControl,
  ButtonGroup,
  useMediaQuery,
  TableContainer,
  CircularProgress,
} from "@material-ui/core"

import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"
import RemoveIcon from "@material-ui/icons/Remove"
import BookImage from "../../static/images/book-image.png"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

function CustomAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CustomOrders() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { setIsActiveCart } = useContext(SiteContext)

  const validateForm = useRef(null)
  const [orderNote, setOrderNote] = useState()
  const [loading, setLoading] = useState(false)
  const [cartCount, setCartCount] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [loadingContent, setLoadingContent] = useState(true)
  const [cartItemContent, setCartItemContent] = useState([])
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [openAlertCouponSuccess, setOpenAlertCouponSuccess] = useState({
    isOpen: false,
    message: "",
  })
  const [checkoutForm, setCheckoutForm] = useState({
    cardCVV: "",
    cardName: "",
    cardNumber: "",
    cardEndYear: "",
    cardEndMonth: "",
    emailAddress: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    cardCVV: false,
    cardCVVText: "",
    cardName: false,
    cardNameText: "",
    cardNumber: false,
    cardNumberText: "",
    cardEndYear: false,
    cardEndYearText: "",
    emailAddress: false,
    emailAddressText: "",
    cardEndMonth: false,
    cardEndMonthText: "",
  })
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [formPhone, setFormPhone] = useState()
  const [fixedPrice, setFixedPrice] = useState("")
  // const [directAddUser, setDirectAddUser] = useState(false)

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const currencyIcon = currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    setOpenAlertErrorOnSubmit(false)
    setOpenAlertCouponSuccess({ isOpen: false })
  }

  const breadcrumbs = [
    {
      text: t("breadcrumbs.customOrder"),
    },
  ]

  const enterdCardNumber = () => {
    if (isSubmitted) {
      if (checkoutForm.cardNumber.length < 13 || checkoutForm.cardNumber.length > 19) {
        setErrorMassege({
          ...errorMassege,
          cardNumber: true,
          cardNumberText: t("errors.invalidPhoneNumber"),
        })
      }
    }
  }

  const handleChange = prop => event => {
    if (prop === "cardNumber" && isSubmitted) {
      enterdCardNumber()
    }
    setCheckoutForm({ ...checkoutForm, [prop]: event.target.value })
  }

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  let localCurrency = windowGlobal && windowGlobal.localStorage.getItem("currencyKey")

  const url = windowGlobal && new URL(windowGlobal.location.href)
  const orderId = windowGlobal && url.searchParams.get("orderId")

  const fetchCartData = () => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
          currency: localCurrency,
        },
      })
      .then(res => {
        setFixedPrice(res.data.custom_order && res.data.custom_order.fixedPrice > 0 ? res.data.custom_order.fixedPrice : "")
        // setDirectAddUser(res.data.custom_order && res.data.custom_order.directAddUser)
        setOrderNote(res.data.custom_order.note)
        res.data.order_items && setCartItemContent(res.data.order_items)
        setLoadingContent(false)
      })
      .catch(() => {
        setLoadingContent(false)
      })
  }

  useEffect(() => {
    fetchCartData()
  }, [jwtForProfile, i18n, localCurrency])

  const handleDeleteItem = cartItemId => {
    setCartItemContent(cartItemContent.filter(item => item.id !== cartItemId))
  }

  useEffect(() => {
    setTotalPrice(
      cartItemContent
        .map(item =>
          !item.discountedPrice > 0
            ? item.discountPrice > 0
              ? item.discountPrice * item.quantity
              : item.price * item.quantity
            : item.discountedPrice
        )
        .reduce((a, b) => a + b, 0)
    )

    setDiscountPrice(
      cartItemContent
        .map(item => (item.discountPrice ? item.price - item.discountPrice : 0) * item.quantity)
        .reduce((a, b) => a + b, 0)
    )

    setTotalAmount(cartItemContent.map(item => item.price * item.quantity).reduce((a, b) => a + b, 0))
  }, [cartItemContent])

  const handleClickBadgeReduce = async itemId => {
    setLoading(true)

    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity - 1, 0) }
      })

    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount + 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  const handleClickBadgeIncrease = async itemId => {
    setLoading(true)

    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity + 1, 0) }
      })

    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount - 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  if (documentGlobal) {
    if (documentGlobal.getElementById("cardCVV")) {
      documentGlobal.getElementById("cardCVV").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardNumber")) {
      documentGlobal.getElementById("cardNumber").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardEndMonth")) {
      documentGlobal.getElementById("cardEndMonth").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
    if (documentGlobal.getElementById("cardEndYear")) {
      documentGlobal.getElementById("cardEndYear").addEventListener("keypress", function (evt) {
        if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
          evt.preventDefault()
        }
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    setLoading(true)
    setDisabledBtn(true)
    setIsSubmitted(true)

    if (validateForm.current.checkValidity()) {
      validateForm.current.submit()
      // setTimeout(() => {
      setLoading(false)
      setDisabledBtn(false)
      setIsSubmitted(false)

      // axios
      //   .post(
      //     `${process.env.GATSBY_API_URL}/update-and-pay-custom-order`,
      //     {
      //       cartType: "other",
      //       orderId: orderId,
      //       items: cartItemContent.map(item => {
      //         return { id: item.id, quantity: item.quantity }
      //       }),
      //       contactInfo: {
      //         name: checkoutForm.firstName + checkoutForm.lastName,
      //         phone: formPhone,
      //         email: checkoutForm.emailAddress,
      //       },
      //       cardName: checkoutForm.cardName,
      //       cardNumber: checkoutForm.cardNumber,
      //       cardCVV: checkoutForm.cardCVV,
      //       cardEndMonth: checkoutForm.cardEndMonth,
      //       cardEndYear: checkoutForm.cardEndYear,
      //     },
      //     { headers: { Authorization: `Bearer ${jwtForProfile}` } }
      //   )
      //   .then(() => {
      //     setOpenAlertSuccess(true)
      //     setDisabledBtn(false)
      //     setLoading(false)
      //     setTimeout(() => {
      //       setOpenAlertSuccess(false)
      //     }, 3000)
      //     setTimeout(() => {
      //       navigate("/")
      //     }, 3500)
      //   })
      //   .catch(() => {
      //     setLoading(false)
      //     setDisabledBtn(false)
      //   })
    } else {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setOpenAlertErrorOnSubmit(false)
      }, 3000)
      if (!checkoutForm.cardName) {
        return setErrorMassege({
          cardName: true,
          cardNameText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardNumber) {
        return (
          enterdCardNumber(),
          setErrorMassege({
            cardNumber: true,
            cardNumberText: t("errors.requiredField"),
          })
        )
      }
      if (!checkoutForm.cardCVV) {
        return setErrorMassege({
          cardCVV: true,
          cardCVVText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndMonth) {
        return setErrorMassege({
          cardEndMonth: true,
          cardEndMonthText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndYear) {
        return setErrorMassege({
          cardEndYear: true,
          cardEndYearText: t("errors.requiredField"),
        })
      }
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
    }
  }

  const isMobile = useMediaQuery("(max-width:960px)")

  useEffect(() => {
    setIsActiveCart(cartItemContent.length > 0 ? true : false)
  }, [handleClickBadgeReduce, cartItemContent])

  return (
    <>
      <SEO title={t("breadcrumbs.customOrder")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <Container className={classes.mainContainer} display={jwtForProfile ? `block` : `none`}>
        {windowGlobal && (cartItemContent.length > 0 || (fixedPrice && fixedPrice > 0)) ? (
          <>
            <Grid container spacing={3}>
              {orderNote && (
                <Grid item xs={12} md={12}>
                  <Alert severity="warning" className={classes.orderNoteWarning}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: orderNote.replaceAll("\n", "<br/>"),
                      }}
                    />
                  </Alert>
                  <br />
                </Grid>
              )}
              <Grid item xs={12} md={6} className={`${classes.textCenter} ${classes.checkoutSidebar}`}>
                <Paper elevation={3} className={classes.checkoutPaperSideBar}>
                  {cartItemContent.map(item => (
                    <Grid container key={item.id} className={classes.checkoutPaperGridSideBar}>
                      <Grid item sm={3}>
                        {item.book.image ? (
                          <img
                            src={`${process.env.GATSBY_API_URL}${item.book.image.url}`}
                            alt="book"
                            className={classes.checkoutBookImageSideBar}
                          />
                        ) : (
                          <img src={BookImage} alt="book" className={classes.checkoutBookImageSideBar} />
                        )}
                      </Grid>
                      <Grid item sm={7} container className={classes.headerPopoversCartDesktop}>
                        <Grid item sm={12}>
                          <Typography>{item.book.title}</Typography>
                        </Grid>
                        {!fixedPrice && (
                          <>
                            <Divider variant="middle" className={classes.checkoutSidebarDivider} />
                            <Grid item sm={5} xs={12}>
                              <Grid container>
                                <Grid item xs={6} sm={12}>
                                  {t("cartItems.quantity")}:
                                  <Paper elevation={0} className={classes.cartItemsCountPaper}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                      className={classes.countItemBox}
                                    >
                                      <Box>
                                        <IconButton
                                          color="primary"
                                          aria-label="add to shopping cart"
                                          onClick={() => handleClickBadgeReduce(item.id, item.quantity)}
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Box>
                                      <Box className={classes.cartItemsCountnumber}>
                                        {item.quantity}
                                        <Backdrop className={classes.backdrop} open={loading}>
                                          <CircularProgress color="inherit" />
                                        </Backdrop>
                                      </Box>
                                      <Box>
                                        <IconButton
                                          color="primary"
                                          aria-label="add to shopping cart"
                                          onClick={() => handleClickBadgeIncrease(item.id, item.quantity)}
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </Paper>
                                </Grid>
                                {isMobile && (
                                  <Grid item xs={6} sm={false}>
                                    <Button variant="contained" color="secondary" style={{ marginTop: 10 }}>
                                      {t("checkout.delete")}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>

                            <Grid item sm={7} xs={12}>
                              <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a book price table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {t("cartItems.bookPrice")}
                                      </TableCell>

                                      <TableCell align="right">
                                        {item.price.toFixed(2)}
                                        {currencyIcon}
                                      </TableCell>
                                    </TableRow>
                                    {item.discountPrice && (
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          {t("cartItems.discountedPrice")}
                                        </TableCell>

                                        <TableCell align="right">
                                          {(item.discountPrice * item.quantity).toFixed(2)}
                                          {currencyIcon}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {t("cartItems.total")}
                                      </TableCell>

                                      <TableCell align="right">
                                        {item.discountPrice
                                          ? (item.discountPrice * item.quantity).toFixed(2)
                                          : (item.price * item.quantity).toFixed(2)}
                                        {currencyIcon}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item md={2}>
                        {!isMobile && (
                          <IconButton
                            aria-label="delete"
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteItem(item.id)}
                            className={classes.margin}
                          >
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        )}
                      </Grid>
                      <Divider variant="middle" className={classes.checkoutSidebarDivider} />
                    </Grid>
                  ))}
                  <div className={`${classes.textAlignLeft} ${classes.totalDataSideBar}`}>
                    <div>
                      {!fixedPrice && (
                        <>
                          <Typography variant="h5" component="p">
                            {t("cartItems.subTotal")}:
                          </Typography>
                          <Typography variant="h5" component="p">
                            {t("checkout.discount")}:
                          </Typography>
                        </>
                      )}
                      <Typography variant="h5" component="p">
                        {!fixedPrice ? t("cartItems.total") : t("singleBook.price")}:
                      </Typography>
                    </div>
                    <div style={{ direction: "ltr" }}>
                      {!fixedPrice && (
                        <>
                          <Typography variant="h5" component="p">
                            {totalAmount.toFixed(2)}
                            {currencyIcon}
                          </Typography>
                          <Typography variant="h5" component="p">
                            {discountPrice.toFixed(2)}
                            {currencyIcon}
                          </Typography>
                        </>
                      )}
                      <Typography variant="h5" component="p">
                        {!fixedPrice ? totalPrice.toFixed(2) : fixedPrice.toFixed(2)}
                        {currencyIcon}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textCenter}>
                <form
                  ref={validateForm}
                  id="validateForm"
                  method="post"
                  action="https://gwthani.com/sendCustomPayment.php"
                >
                  <input type="hidden" value={jwtForProfile} name="userToken" />
                  <input type="hidden" value={orderId} name="orderId" />
                  {cartItemContent.map((element, index) => (
                    <React.Fragment key={index}>
                      <input type="hidden" value={element.id} name={`items[${index}][id]`} />
                      <input type="hidden" value={element.quantity} name={`items[${index}][quantity]`} />
                    </React.Fragment>
                  ))}

                  <Typography variant="h6" component="h3" className={classes.boldFont}>
                    {t("checkout.personalDetails")}
                  </Typography>
                  <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={t("checkout.firstName")}
                      variant="outlined"
                      onChange={handleChange("firstName")}
                    />
                  </FormControl>
                  <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={t("checkout.lastName")}
                      variant="outlined"
                      onChange={handleChange("lastName")}
                      error={errorMassege.lastName}
                      helperText={errorMassege.lastNameText}
                    />
                  </FormControl>
                  <InputPhone phoneValue={setFormPhone} />
                  <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                    <TextField
                      id="emailAddress"
                      name="emailAddress"
                      label={t("checkout.emailAddress")}
                      variant="outlined"
                      error={errorMassege.emailAddress}
                      helperText={errorMassege.emailAddressText}
                      onChange={handleChange("emailAddress")}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <Typography variant="h6" component="h3" className={classes.boldFont}>
                    {t("checkout.paymentDetails")}
                  </Typography>
                  <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                    <TextField
                      required
                      id="cardName"
                      name="cardName"
                      type="text"
                      label={t("checkout.cardName")}
                      variant="outlined"
                      onChange={handleChange("cardName")}
                      error={errorMassege.cardName}
                      helperText={errorMassege.cardNameText}
                    />
                  </FormControl>
                  <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                    <TextField
                      required
                      id="cardNumber"
                      name="cardNumber"
                      type="text"
                      label={t("checkout.cardNumber")}
                      variant="outlined"
                      onChange={handleChange("cardNumber")}
                      error={errorMassege.cardNumber}
                      helperText={errorMassege.cardNumberText}
                    />
                  </FormControl>
                  <Grid container>
                    <Grid item xs={4} className={classes.paddingLeftRight}>
                      <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                        <TextField
                          required
                          id="cardEndMonth"
                          name="cardMonth"
                          label={t("checkout.cardEndMonth")}
                          variant="outlined"
                          error={errorMassege.cardEndMonth}
                          helperText={errorMassege.cardEndMonthText}
                          onChange={handleChange("cardEndMonth")}
                          inputProps={{
                            autoComplete: "cc-exp-month",
                            "x-autocompletetype": "cc-exp-month",
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} className={classes.paddingLeftRight}>
                      <FormControl className={`${classes.margin} ${classes.formConrolSignup}`} fullWidth>
                        <TextField
                          required
                          id="cardEndYear"
                          name="cardEndYear"
                          label={t("checkout.cardEndYear")}
                          variant="outlined"
                          error={errorMassege.cardEndYear}
                          helperText={errorMassege.cardEndYearText}
                          onChange={handleChange("cardEndYear")}
                          inputProps={{
                            autoComplete: "cc-exp-year",
                            "x-autocompletetype": "cc-exp-year",
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} className={classes.paddingLeftRight}>
                      <FormControl fullWidth className={`${classes.margin} ${classes.formConrolSignup}`}>
                        <TextField
                          required
                          id="cardCVV"
                          name="cardCVV"
                          type="password"
                          autoComplete="off"
                          label={t("checkout.cardCVV")}
                          variant="outlined"
                          onChange={handleChange("cardCVV")}
                          error={errorMassege.cardCVV}
                          helperText={errorMassege.cardCVVText}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
                <ButtonGroup
                  color="primary"
                  aria-label="Order"
                  disabled={disabledBtn}
                  onClick={handleSubmit}
                  className={`${classes.orderBtn} ${classes.margin} button`}
                >
                  <Button type="submit" variant="contained" className={classes.orderBookBtn}>
                    {disabledBtn && <CircularProgress size={26} />}
                    {!disabledBtn && t("orderBook.orderBookBtn")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        ) : (
          <div>
            {jwtForProfile && loadingContent ? (
              <Typography align="center">{t("myProfile.loading")}</Typography>
            ) : jwtForProfile ? (
              <Typography align="center">{t("cartItems.noContent")}</Typography>
            ) : (
              ""
            )}
          </div>
        )}
      </Container>
      <SnackbarProvider maxSnack={3}>
        <Snackbar open={openAlertSuccess} autoHideDuration={3000} onClose={handleClose}>
          <CustomAlert onClose={handleClose} severity="success">
            {t("checkout.orderCompleted")}
          </CustomAlert>
        </Snackbar>
        <Snackbar open={openAlertCouponSuccess.isOpen} autoHideDuration={3000} onClose={handleClose}>
          <CustomAlert onClose={handleClose} severity="success">
            {t("checkout.couponApplied")}
          </CustomAlert>
        </Snackbar>
        <Snackbar open={openAlertErrorOnSubmit} autoHideDuration={3000} onClose={handleClose}>
          <CustomAlert onClose={handleClose} severity="error">
            {t("checkout.wrongEntry")}
          </CustomAlert>
        </Snackbar>
      </SnackbarProvider>
      <Box component="div" display={!jwtForProfile ? `block` : `none`}>
        <Container className={classes.notSignedIn}>
          <Typography variant="subtitle1">{t("myProfile.noPremissions")}</Typography>
          <br />
          <Sigin />
        </Container>
      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  notSignedIn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: "26vh",
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },

  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input, select": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  orderBookBtn: {
    height: 40,
    borderRadius: 10,
  },
  textCenter: {
    textAlign: "center",
    "& > .MuiTypography-root": {
      marginBottom: theme.spacing(2),
      fontSize: 26,
    },
  },
  checkoutSidebar: {
    margin: "2rem 0",
    padding: "0rem 3rem !important",
  },
  checkoutBookImageSideBar: {
    maxWidth: "100%",
  },
  checkoutPaperGridSideBar: {
    display: "flex",
    alignItems: "center",
    padding: "14px",
  },
  checkoutSidebarDivider: {
    width: "80%",
    margin: "12px auto",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  totalDataSideBar: {
    maxWidth: "80%",
    margin: "0 auto",
    display: "flex",
    textAlign: "left",
    justifyContent: "space-between",
    "& div:last-child": {
      direction: "ltr",
    },
  },
  checkoutPaperSideBar: {
    padding: "16px 0 16px 12px",
  },
  checkoutPaperSideBar2: {
    padding: "16px 12px",
  },
  orderBtn: {
    marginTop: 14,
    width: "100%",
    textAlign: "center",
    "& > .MuiButtonBase-root": {
      width: 250,
      margin: "0 auto",
      maxWidth: "100%",
    },
  },
  confirmSmsDialog: {
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "100%",
      height: "50%",
    },
  },
  confirmSmsEmbed: {
    width: "100%",
    height: "100%",
  },
  paymentTypeTabsIcon: {
    fontSize: 32,
    width: 32,
    height: 32,
  },
  paymentTypesTabs: {
    minWidth: 130,
  },
  inputCopy: {
    border: 0,
    color: "#000",
    width: 88,
    backgroundColor: "transparent",
  },
  tabPanels: {
    textAlign: "left",
  },
  boldFont: {
    fontWeight: "bold",
    textAlign: "center",
  },
  orderNote: {
    marginTop: 32,
  },
  orderNoteWarning: {
    width: "100%",
    maxWidth: 767,
    margin: "0 auto",
    lineHeight: 1.8,
    fontSize: 16,
    "& .MuiSvgIcon-root": {
      alignSelf: "center",
    },
  },
  table: {
    color: "#fff",
    "& .MuiTableRow-root > th": {
      paddingRight: 0,
      paddingLeft: 10,
    },
    "& .MuiTableRow-root > td": {
      paddingRight: 10,
      paddingLeft: 0,
    },
  },
}))
